import React, { useEffect, useState, useCallback } from "react";
import "../components/style.css";
import Weather from "./Weather";

function Search() {
  const [City, setCity] = useState("London, GB");
  const [Info, setInfo] = useState({});

  const handleInputChange = useCallback((e) => {
    setCity(e.target.value)
  }, [setCity])

  const getWeather = async () => {
    try {
      let url = `https://api.openweathermap.org/data/2.5/weather?q=${City}&units=metric&appid=fefb9f041d8e2599014e4efe5ed823ed`;

      let res = await fetch(url);
      let data = await res.json();
      const { temp, temp_min,temp_max, humidity, pressure } = data.main;
      const { description: description } = data.weather[0];
      const { icon: icon } = data.weather[0];
      const { all:cloud } = data.clouds;
      const { name } = data;
      const { speed } = data.wind;
      const { country} = data.sys;

      const WeatherInfo = {
        temp,
        temp_min,
        temp_max,
        icon,
        description,
        humidity,
        cloud,
        pressure,
        name,
        speed,
        country
      };

      setInfo(WeatherInfo);
      //console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <>
        <div className="bar">
            <input type="searh" className="searchbar" id="search" placeholder="London,GB" value={City} onInput={handleInputChange} onKeyUp={getWeather} />
        </div>
      <Weather {...Info} />
    </>
  );
}

export default Search;
