import React, { useState, useEffect } from "react";

function Weather({
  temp,
  temp_min,
  temp_max,
  icon,
  description,
  humidity,
  cloud,
  weatherType,
  name,
  country
}) {



  return (
    <>
      
      <div className="ppp-grid">
        <ul>
          <li>
            <p className="ppp-large">{name}, {country}</p>
            <p className="ppp-regular">{new Date().toLocaleString()}</p>
          </li>
          <li>
            <p className="ppp-large">{temp}&deg;C</p>
            <p className="ppp-regular">min {temp_min}&deg;C - max {temp_max}&deg;C</p>
          </li>
          <li>
            <p className="ppp-large"><img src={`https://openweathermap.org/img/w/${icon}.png`} /></p>
            <p className="ppp-regular">{description}</p>
          </li>
          <li>
            <a href="https://www.perryliu.co.uk/" target="_blank">Simple Weather App by Perry Liu</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Weather;
